import React from "react";
import Markdown from "markdown-to-jsx";
import Styled from "@emotion/styled";
import { theme } from "../utils/global-styles/theme";
import Layout from "../components/Layout/Layout";
import { graphql } from "gatsby";

const terms = ({ 
    data,
    pageContext: { locale, marketingModal },
}) => {
    const page = data.pages.nodes[0],
    Wrapper = Styled.div`
      margin: 5em 15em;
      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin: 24px 5%;
      }
    `,
    MarkdownParagraph = Styled(Markdown)`
    p {
      font-size: 20px;
      line-height: 31px;
      align: left;
      margin-bottom: 1em;
    }
    strong {
      font-family: ${theme.fontFaces.agStandardBold};
      display: block;
      margin-top: 12px;
    }
    ul, ol {
      list-style: disc;
      padding-left: 16px;
    }
    h3 {
      font-size: 24px;
    }
    `;

  return (
    <Layout locale={locale} marketingModal={marketingModal}>
      <Wrapper>
        <MarkdownParagraph
          options={{
            overrides: {
              strong: {
                props: {
                  className: "bold",
                },
              },
              ol: {
                props: {
                  className: "ordered-list",
                },
              },
              h3: {
                props: {
                  className: "primary",
                },
              },
              a: {
                props: {
                  className: "primary secondary-hover",
                },
              },
            },
          }}
        >
          {page.contentModules[0].description.description}
        </MarkdownParagraph>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
    query ($locale: String! ){
      pages: allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Terms of Use Page" },
        node_locale: { eq: $locale} }
        ) {
            nodes {
            heading
            shortDescription
            contentModules {
            ... on ContentfulTitleDescription {
                description {
                description
                }
            }
            }
        }
        }
    }
`;


terms.propTypes = {};

export default terms;
